@each $state, $data in $form-validation-states {
  .#{$state}-feedback {
    position: relative;
    padding-left: 16px;
    line-height: 1;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      display: block;
      width: 16px;
      height: 16px;
      background-image: escape-svg(map-get($data, "icon"));
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }
  }

  .form-check-input {
    @include form-validation-state-selector($state) {
      @if $state== "valid" {
        border-color: $form-check-input-border-color;
      }

      &:checked {
        border-color: $form-check-input-checked-border-color;
        background-color: $form-check-input-checked-bg-color;
      }
      ~ .form-check-label {
        color: $body-color;
      }
    }
  }
}

.form-select,
.form-control {
  @include form-validation-state-selector("valid") {
    border-color: $input-border-color;

    &:focus {
      border-color: $input-focus-border-color;
    }
  }

  @include form-validation-state-selector("invalid") {
    &:focus {
      border-color: $input-focus-border-color;
    }
  }
}
